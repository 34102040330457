import "./NavigationBar.css";

import { ReactComponent as SecretAegisLogo } from "../../assets/secret-aegis-logo.svg";

function NavigationBar(props) {
  return (
    <div className='NavigationBar'>
      <div className='NavigationBarContainer'>
        <div className='Logo'>
          <SecretAegisLogo
            className='SecretAegisLogo'
            height='50px'
            width='50px'
          />
          <p>Secret Aegis</p>
        </div>
        {/*<div className="Navigation">
                    <p>Features</p>
                    <p>Pricing</p>
                    <p>Contact</p>
                </div>*/}
      </div>
      <div className='Main'>{props.component}</div>
    </div>
  );
}

export default NavigationBar;
