import { Home, Secret } from './routes';

const PublicRoutes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/secret/:uuid",
    component: Secret
  }
];

export default PublicRoutes;
