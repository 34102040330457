import "./App.css";
import { Switch } from "react-router";

import { PublicRoute } from "./components";
import { NavigationBar } from "./components";

import PublicRoutes from "./PublicRoutes";

function App() {
  return (
    <div className="App">
      <Switch>
        {PublicRoutes.map((route) => (
          <PublicRoute
            exact
            path={route.path}
            key={route.path}
            component={<NavigationBar component={<route.component />} />}
          />
        ))}
      </Switch>
    </div>
  );
}

export default App;
