import React from "react";
import { ReactComponent as SafeVector } from "../../assets/safe-information.svg";
import { StyledButton } from "../../components";
import "./Home.css";

function Home(props) {
  return (
    <div className='Home'>
      <div className='Hero'>
        <h1 className='Heading'>
          Securely share secrets
          <br />
          with your team on Slack
        </h1>
        <p className='Subheading'>
          Secret Aegis makes it easy to keep private data safe with a simple
          slash command.
        </p>
        <a href='https://slack.com/oauth/v2/authorize?client_id=1998154771346.2010770292033&scope=chat:write,chat:write.public,commands,app_mentions:read&user_scope='>
          <StyledButton
            type='button'
            width='280px'
            text='Add to Slack'
            disabled={false}
          />
        </a>
      </div>
      <div className='ImageContainer'>
        <SafeVector />
      </div>
    </div>
  );
}

export default Home;
