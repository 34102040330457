import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { StyledButton } from "../../components";

import "./Secret.css";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Secret(props) {
  const [isValidatingSecret, setIsValidatingSecret] = useState(true);
  const [isSecretValid, setIsSecretValid] = useState(true);
  const [isFetchingSecret, setIsFetchingSecret] = useState(false);
  const [isShowingSecret, setIsShowingSecret] = useState(false);
  const [secretText, setSecretText] = useState(null);

  let { uuid } = useParams();
  let query = useQuery();

  useEffect(() => {
    setIsValidatingSecret(true);
    fetch(
      `https://api.secretaegis.com/secret/${uuid}?k=${query.get("k")}`
    ).then(function (response) {
      if (response.status !== 200) {
        setIsSecretValid(false);
      }
      setIsValidatingSecret(false);
    });
    // eslint-disable-next-line
  }, []);

  const onClick = () => {
    setIsFetchingSecret(true);
    fetch(`https://api.secretaegis.com/secret/${uuid}?k=${query.get("k")}`, {
      method: "POST",
    }).then(function (response) {
      return response.text().then(function (text) {
        setSecretText(text);
        setIsShowingSecret(true);
        setIsFetchingSecret(false);
      });
    });
  };

  return (
    <div className='Secret'>
      {isValidatingSecret ? (
        <h2>Just a moment...</h2>
      ) : (
        <div>
          {isSecretValid ? (
            <div>
              <h2>Shhh...someone shared a secret with you.</h2>
              {!isShowingSecret ? (
                <div className='ButtonContainer'>
                  <StyledButton
                    type='button'
                    text={isFetchingSecret ? "Loading..." : "View secret"}
                    width='500px'
                    onClick={onClick}
                    disabled={isFetchingSecret}
                  />
                  <p className='SecretHint'>Secrets are only viewable once.</p>
                </div>
              ) : (
                <div className='SecretBody'>
                  <p className='SecretText'>{secretText}</p>
                </div>
              )}
            </div>
          ) : (
            <div>
              <h2>Hmm...it doesn't look like that's a valid secret.</h2>
              <p>It may have been viewed or is expired.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Secret;
