import React from "react";
import { Redirect, Route } from "react-router-dom";

const PublicRoute = ({ component: Component, loggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !loggedIn ? (
          Component
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PublicRoute;
