import styled from "styled-components";

function StyledButton(props) {
  const LocalButton = styled.button`
    border-radius: 50px;
    background-color: ${(props) =>
      props.disabled === false ? "#dc143c" : "#959595"};
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
    border: none;
    width: ${props.width};
  `;

  return (
    <div className='StyledButton'>
      <LocalButton
        type={props.type}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.text}
      </LocalButton>
    </div>
  );
}

export default StyledButton;
